.contact-container {
    background-color: #111;
    color: #fff;
    padding: 2rem;
}

.contact-form {
    max-width: 400px;
    margin: 40px auto;
}

.contact-us-title {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    color: #ffcc00;
}

.contact-us-submit {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.form-group {
    margin-bottom: 1rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
}

input,
textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #666;
    background-color: #222;
    color: #fff;
}

.contact-us-describe {
    max-width: 1050px;
    margin: 0 auto;
}

.error {
    border-color: #ff4444;
}

.error-message {
    color: #ff4444;
    margin-top: 0.25rem;
}

button {
    background-color: #ffcc00;
    color: #111;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

button:hover {
    background-color: #111;
    color: #ffcc00;
}