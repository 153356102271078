.legal-disclaimer-btn {
    width: 50%;
    justify-content: start;
    display: flex;
    padding-left: 10px;
}

.connect-information {
    width: 50%;
    justify-content: end;
    display: flex;
    padding-right: 10px;
}

.Footer-links a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.Footer-links a:hover {
  color: #ffcc00;
}