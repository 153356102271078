.MissionStatement-container {
    background-color: #111;
    color: #fff;
    padding: 2rem;
}

.MissionStatement-title {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    color: #ffcc00;
}

.MissionStatement-new-point {
    margin: 15px auto;
}


.MissionStatement-title2 {
    color: rgb(235, 140, 77);
    display: flex;
    /* justify-content: center; */
    margin: 15px auto;
    text-decoration: underline;
}

.MissionStatement-title3 {
    color: #ffcc00;
    display: flex;
    margin: 15px auto;
}

.MissionStatement-higlight {
    color: #ffcc00;
    margin: 0px 4px;
}

.MissionStatement-describe {
    max-width: 1400px;
    margin: 0 auto;
}

.MissionStatement-sub-describe {
    margin: 15px auto;
}

.sublist-disclaimer {
    list-style: none;
    margin: 10px auto;
}

.MissionStatement-sub-list {
    list-style-type:disc;
    text-decoration: underline;
}