.Photos-container {
    background-color: #111;
    color: #fff;
    padding: 2rem;
}

.Photos-title {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    color: #ffcc00;
}

.Photos-higlight {
    color: #ffcc00;
    margin: 0px 4px;
}

.Photos-describe {
    max-width: 1400px;
    margin: 0 auto;
    list-style: none;
}

.Photos-arrow {
    margin-right: 10px;
}

.Photos-list {
    display: flex;
    margin-bottom: 20px;
}

.Photos-list svg {
    min-width: 15px;
    min-height: 15px;
}

.Photos-text {
    display: block;
}