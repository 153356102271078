.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 10px;
}

.image-grid img {
    width: 99%;
    height: 350px;
    object-fit: cover;
    border: 0.5px solid #ffff
}

.image {
    width: 100%;
    height: 100%;
    transition: transform 4s;
}

.flipped .image {
    transform: rotateY(180deg);
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.fullscreen-image img {
    max-width: 90%;
    max-height: 80vh;
    display: block;
    margin: 0 auto;
    height: auto;
    object-fit: contain;
}
