.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #111;
  color: #fff;
}

.home-page {
padding: 5px 0;}

.header {
  padding: 1rem;
  background-color: #222;
  text-align: center;
}

.footer {
  padding: 1rem;
  background-color: #222;
  text-align: center;
  margin-top: auto;
}
