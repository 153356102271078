.hero-slider .slick-prev {
    left: 25px;
    z-index: 9;
}

.hero-slider .slick-next {
    right: 25px;
}

.image-container {
    width: 100%;
    margin: 0 auto;
}

.responsive-image {
    width: 100%;
    height: calc(100vh - 184px);
    object-fit: cover;
}