.about-container {
    background-color: #111;
    color: #fff;
    padding: 2rem;
}

.contact-form {
    max-width: 400px;
    margin: 40px auto;
}

.about-us-title {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    color: #ffcc00;
}

.about-us-higlight {
    color: #ffcc00;
    text-decoration: underline;
    margin: 0px 4px;
}

.about-us-describe {
    max-width: 1400px;
    margin: 0 auto;
    list-style: none;
}

.about-us-arrow {
    margin-right: 10px;
}

.about-us-list {
    display: flex;
    margin-bottom: 20px;
}

.about-us-list svg {
    min-width: 15px;
    min-height: 15px;
}

.about-us-text {
    display: block;
}

.about-sub-text {
    margin: 5px auto;
}